import redirectToHome from './redirectToHome';
import useToast from './useToast';
import api from '../api';
import { Preferences as Storage } from '@capacitor/preferences';
import UserTypes from '../enums/UserTypes';

export default function () {
  const { openToast } = useToast();
  const { redirectTo } = redirectToHome();
  
  async function getMerchantCard(merchant_id) {
    try {
      var form = new FormData();
      form.append("merchant_id", merchant_id);
      const response = await api.post('/merchantCard', form);
      if(response.status === 200) {
        if (response.data && response.data.data && response.data.data.vipCard) {
          return response.data.data.vipCard;
        }
        return null; 
      }
      console.log("vipcard.js getMerchantCard ok but no 200", response);
      throw new Error("Request failed with status: " + response.status);
    } catch(error) {
      console.log("vipcard.js getMerchantCard error", error);
      throw error;
    }
  }
    
  async function getMemberVipCardInfo(vipcard_id) {
    const token = await Storage.get({ key: 'token' });
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || !user.userId || !vipcard_id) {
      return "";
    }
    try {
      var form = new FormData();
      form.append("token", token.value);
      form.append("vipcard_id", vipcard_id);
      form.append("member_id", user.userId);
      const response = await api.post('/memberVipCardInfo', form);
      if(response.status === 200) {
        return response.data; 
      }
      console.log("vipcard.js getMemberVipCardInfo ok but no 200", response);
      throw new Error("Request failed with status: " + response.status);
    } catch(error) {
      console.log("vipcard.js getMemberVipCardInfo error", error);
      throw error;
    }
  }

  // for client and admin
  async function getVipCardInfo(id) {
    const token = await Storage.get({ key: 'token' });
    try {
      var form = new FormData();
      form.append("token", token.value);
      form.append("id", id);
      const response = await api.post('/vipCardInfo', form);
      if(response.status === 200) {
        return response.data; 
      }
      console.log("vipcard.js getVipCardInfo ok but no 200", response);
      throw new Error("Request failed with status: " + response.status);
    } catch(error) {
      console.log("vipcard.js getVipCardInfo error", error);
      throw error;
    }
  }

  async function loadMemberCardRecords(conditions) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || (user.memberType == UserTypes.MEMBER)) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("conditions", JSON.stringify(conditions));
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/loadMemberCardRecords', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Load member card records Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  // for client
  async function myVipCard() {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/myVipCard', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Load my vip card error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function updateMemberCardOrder(orderList) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("orderList", JSON.stringify(orderList));
    const headers = { 'Content-Type': 'multipart/form-data' };
    try {
      const response = await api.post('/updateMemberCardOrder', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Update MemberCard order error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  // type:  1 for points, 2 for balance
  async function loadMyCardRecords(cardId, type) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      openToast('Please login first', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("id", cardId);
    form.append("type", type);
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/loadMyCardRecords', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Load my vip card records error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  // if merchant_id is not 0, system will check if link pos vip.
  async function applyvipcard(vipcard_id, merchant_id=0) {
    console.log("vipcard.js applyvipcard 1", vipcard_id)
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || (user.memberType != UserTypes.MEMBER)) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      // return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("vipcard_id", vipcard_id);
    form.append("member_id", user.userId);
    form.append("merchant_id", merchant_id);
    try {
      const response = await api.post('/applyvipcard', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Claim Vip Card Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function checkVerifyPosVip(merchant_id) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || (user.memberType != UserTypes.MEMBER)) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      // return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("merchant_id", merchant_id);
    try {
      const response = await api.post('/checkVerifyPosVip', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Check VerifyPosVip Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function applyVipCardWithPosVip(merchant_id, vipCard_id, question, answer, phone='') {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || (user.memberType != UserTypes.MEMBER)) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      // return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("merchant_id", merchant_id);
    form.append("vipCard_id", vipCard_id);
    form.append("question", question);
    form.append("answer", answer);
    form.append("phone", phone);
    try {
      const response = await api.post('/applyVipCardWithPosVip', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Claim vipcard with posvip Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function getPosVipInfo(memberVipCard_id) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || (user.memberType != UserTypes.MEMBER)) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      // return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("memberVipCard_id", memberVipCard_id);
    try {
      const response = await api.post('/getPosVipInfo', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get pos vip info error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  return {
    openToast,
    redirectTo,
    getMerchantCard,
    getMemberVipCardInfo,
    getVipCardInfo,
    loadMemberCardRecords,
    myVipCard,
    updateMemberCardOrder,
    loadMyCardRecords,
    applyvipcard,
    checkVerifyPosVip,
    applyVipCardWithPosVip,
    getPosVipInfo,
  };
}
