import useToast from './useToast';
import api from '../api';
import { Preferences as Storage } from '@capacitor/preferences';
import UserTypes from '../enums/UserTypes';

export default function () {
  const { openToast } = useToast();

  async function applyCoupon(coupon_id, lead_id=0, referrer_id=0) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user || (user.memberType != UserTypes.MEMBER)) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      // return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("coupon_id", coupon_id);
    form.append("member_id", user.userId);
    if (lead_id && referrer_id) {
      form.append("lead_id", lead_id);
      form.append("referrer_id", referrer_id);
    }
    try {
      const response = await api.post('/applycoupon', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Claim coupon Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  // for now this is only for merchant home page
  async function getCouponList(start, limit, filter={}) {
    var form = new FormData();
    const tokenv = await Storage.get({ key: 'token' });
    if (tokenv.value) {
      form.append("token", tokenv.value);
    }
    form.append("start", start);
    form.append("limit", limit);

    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (user) {
      form.append("user_id", user.userId);
    }

    if ((filter.merchant_id != undefined) && filter.merchant_id) {
      form.append("merchant_id", filter.merchant_id);
    }
    if ((filter.couponNumber != undefined) && filter.couponNumber) {
      form.append("couponNumber", filter.couponNumber);
    }
    if ((filter.types != undefined) && filter.types) {
      form.append("types", filter.types);
    }
    if ((filter.tag != undefined) && filter.tag) {
      form.append("tag", filter.tag);
    }
    try {
      const response = await api.post('/coupons', form);
      if (response.status == 200) {
        // openToast('Logged with sucess', 'success', 'top');
        const data = response.data;
        return data;
      }
      openToast('Get coupon Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  // get real coupon by member id
  async function getMemberCoupon(coupon_id) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    // if (!user || (user.memberType == UserTypes.MEMBER)) {
    //   // Permission error
    //   openToast('Permission Denied', 'warning', 'top');
    //   return "";
    // }
    var form = new FormData();
    form.append("token", token);
    form.append("member_id", user.userId);
    form.append("coupon_id", coupon_id);
    try {
      const response = await api.post('/getMemberCoupon', form);
      // console.log("coupon.js getMemberCoupon", response); 
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get coupon Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function getCouponById(id) {
    // const tokenv = await Storage.get({ key: 'token' });
    // if (!tokenv.value) {
    //   // Permission error
    //   openToast('Timeout. Please login again', 'warning', 'top');
    //   return "";
    // }
    // let token = tokenv.value;
    var form = new FormData();
    // form.append("token", token);
    form.append("id", id);
    try {
      const response = await api.post('/getCouponById', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get coupon Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function getCouponListById(id) {
    var form = new FormData();
    form.append("id", id);
    try {
      const response = await api.post('/getCouponListById', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get the Coupon Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  // for client
  async function myCoupon(type) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("type", type);
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/myCoupon', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Load my coupon error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function getItemCouponByNumber(number) {
    var form = new FormData();
    form.append("number", number);
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/getItemCouponByNumber', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get voucher error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  // to get for all merchant, set merchant_id=0.
  async function getMyItemCoupon(merchant_id) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("merchant_id", merchant_id);
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/getMyItemCoupon', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Load my voucher error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function redeemItemCoupon(product_id, memberVipCard_id) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("product_id", product_id);
    form.append("memberVipCard_id", memberVipCard_id);
  
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const response = await api.post('/redeemItemCoupon', form, {headers});
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Redeem voucher error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  return {
    applyCoupon,      // /applycoupon
    getCouponList,    // /coupons
    getMemberCoupon,  // Get real coupon info
    getCouponById,    // /admin/getCouponById
    getCouponListById,  // Get real coupon info
    myCoupon,          // client/myCoupon
    getItemCouponByNumber,
    getMyItemCoupon,
    redeemItemCoupon,
  };
}