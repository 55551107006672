import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs';
import en from './en';
import zh from './zh';
// import settings from '@/settings'

const i18n = new createI18n({
  locale: 'en', // default language
  // silentTranslationWarn: true,
  //formatter: new CustomFormatter(),
  fallbackLocale: 'en',
  messages: {
    en,
    zh,
    // add more language objects as needed
  },
});

export default i18n;